import {
  init as initSentry,
  Replay,
  BrowserTracing,
  EventHint,
} from '@sentry/react';
import { GLOBAL_ERRORS } from '@eversity/domain/constants';

import { ApiErrorResponse } from '@eversity/utils/express';

import config from '../../config/env';

const ERROR_MESSAGES_TO_IGNORE: string[] = [
  GLOBAL_ERRORS.USER_NOT_AUTHENTICATED.MESSAGE,
];

export const bootstrapSentry = () => {
  const ignoreAgents = [
    'GoogleHC/1.0',
    'Elastic-Heartbeat/7.17.5',
    'python-requests/2.28.1',
  ];

  if (config.sentry.enabled) {
    initSentry({
      dsn: config.sentry.dsn,
      environment: config.sentry.env,
      release: config.sentry.release,
      replaysSessionSampleRate: config.sentry.env === 'prod' ? 0.1 : 0.0,
      replaysOnErrorSampleRate: config.sentry.env === 'prod' ? 1.0 : 0.0,
      integrations: [
        new BrowserTracing(),
        new Replay({
          maskAllText: true,
          blockAllMedia: true,
        }),
      ],
      tracesSampleRate: config.sentry.env === 'prod' ? 1.0 : 0.0,
      tracesSampler: (samplingContext) => {
        if (
          ignoreAgents.includes(samplingContext?.request?.headers['user-agent'])
        )
          return false;
        return 0.5;
      },
      beforeSend(event, hint: EventHint) {
        const error = hint.originalException as ApiErrorResponse;

        if (
          error &&
          error.message &&
          ERROR_MESSAGES_TO_IGNORE.includes(error.message)
        ) {
          return null;
        }
        return event;
      },
    });
  }
};
