import { memo, useCallback } from 'react';
import { toast } from 'react-toastify';

import { UnexpectedErrorToast } from '@eversity/ui/design-system';

import { useUser } from '../../../hooks/queries/user';
import { useAcceptLegalDocumentMutation } from '../../../hooks/mutations/user';
import { useLegalDocuments } from '../../../hooks/queries/legalDocuments';

import LegalDocumentsDialog from '../dialog/LegalDocumentsDialog';

/**
 * This component checks what legal documents the user has not accepted yet.
 * For each document not accepted, show a Dialog requesting acceptance.
 */
export const LegalDocumentsRequireAcceptanceBase = () => {
  const { data: user } = useUser();
  const { data: legalDocuments } = useLegalDocuments({
    throwOnError: false,
  });

  const { mutateAsync: acceptLegalDocument, isPending: isLoading } =
    useAcceptLegalDocumentMutation();

  const documentToAccept = legalDocuments?.find(
    (legalDocument) =>
      !user.acceptedLegalDocuments.some(
        ({ legalDocumentType, versionMajor }) =>
          legalDocumentType === legalDocument.type &&
          versionMajor === legalDocument.versionMajor,
      ),
  );

  const hasAcceptedPreviousVersion =
    !!documentToAccept &&
    user.acceptedLegalDocuments.some(
      ({ legalDocumentType }) => legalDocumentType === documentToAccept.type,
    );

  const onConfirmConsent = useCallback(async () => {
    try {
      await acceptLegalDocument(documentToAccept?.type);
    } catch (err) {
      toast.error(<UnexpectedErrorToast />);
    }
  }, [documentToAccept?.type, acceptLegalDocument]);

  return (
    <LegalDocumentsDialog
      isOpen={!!documentToAccept}
      requireConsent
      legalDocument={documentToAccept}
      key={documentToAccept?.type}
      hasUpdate={hasAcceptedPreviousVersion}
      onConfirmConsent={onConfirmConsent}
      isLoading={isLoading}
    />
  );
};

LegalDocumentsRequireAcceptanceBase.displayName =
  'LegalDocumentsRequireAcceptance';

export default memo(LegalDocumentsRequireAcceptanceBase);
