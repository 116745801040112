import { ComponentProps, memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useUpdateEffect } from 'react-use';
import { useLocation, useNavigate } from 'react-router-dom';
import { noop } from 'lodash';
import { FallbackRender } from '@sentry/react';
import { ResponseError } from 'superagent';

import { InternalServerError, NotFoundError } from '@eversity/ui/domain';

import routes from '../../routes';

export type ErrorViewProps = Partial<
  Omit<ComponentProps<FallbackRender>, 'error'>
> & {
  error?: ComponentProps<FallbackRender>['error'] | ResponseError;
};

export const ErrorViewBase = ({
  error = null,
  resetError = noop,
}: ErrorViewProps) => {
  const location = useLocation();
  const navigate = useNavigate();

  const onReturnToHome = useCallback(
    () => navigate(routes.APP.ROOT, { replace: true }),
    [navigate],
  );

  // Reset the error when changing location.
  useUpdateEffect(() => {
    resetError();
  }, [location.pathname, resetError]);

  return error && 'status' in error && error.status >= 500 ? (
    <InternalServerError />
  ) : (
    // Defaults to 404 for unknown routes and every other error (we don't have other pages yet).
    <NotFoundError onReturnToHome={onReturnToHome} />
  );
};

ErrorViewBase.displayName = 'ErrorView';

ErrorViewBase.propTypes = {
  // Error caught by boundary.
  error: PropTypes.shape({
    status: PropTypes.number,
  }),
  resetError: PropTypes.func,
};

export default memo(ErrorViewBase);
