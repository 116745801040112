import { useMemo } from 'react';
import { useDeviceData } from 'react-device-detect';

import { StudentCourse, StudentViewFull } from '@eversity/types/domain';

export enum TYPEFORMS {
  SUGGESTIONS = 'SUGGESTIONS',
  SATISFACTION_SURVEY = 'SATISFACTION_SURVEY',
}

export type TypeformConfig = {
  /** Typeform form id. */
  id: string;
  /** Typeform hidden fields. */
  useHiddenFields: (
    student: StudentViewFull,
    studentCourse?: StudentCourse,
  ) => Record<string, string>;
};

export const TYPEFORM_CONFIGS = {
  [TYPEFORMS.SUGGESTIONS]: {
    id: 'i8luvOgY',
    useHiddenFields: (student, studentCourse) => {
      const { device, browser, os } = useDeviceData(null);

      return useMemo(
        () => ({
          id_crm: student.studentRef,
          student_course_name: studentCourse?.course.title || '',
          student_lastname: student.lastName,
          student_firstname: student.firstName,
          student_course_dispo: '', // TODO studentCourse.plan quand on aura les dispositifis,
          device_info:
            device?.vendor || `${os?.name || ''} ${os?.version || ''}`.trim(),
          browser_info: `${browser?.name || ''} ${
            browser?.version || ''
          }`.trim(),
        }),
        [
          student,
          studentCourse,
          device?.vendor,
          os?.name,
          os?.version,
          browser?.name,
          browser?.version,
        ],
      );
    },
  },

  [TYPEFORMS.SATISFACTION_SURVEY]: {
    id: 'bkbb3Zoi',
    useHiddenFields: (student) => ({
      id_crm: student.studentRef,
    }),
  },
} as const satisfies Record<TYPEFORMS, TypeformConfig>;
