import { useCallback } from 'react';
import { isFunction } from 'lodash';

import { TYPEFORM_CAMPAIGNS } from '../../../domain/feedbacks/typeform/campaigns';
import {
  FeedbackCampaignStatus,
  FeedbackCampaignStatuses,
} from '../../../types';
import { useAppLocalStorage } from './useAppLocalStorage';

type UpdateFeedbackCampaignStatusesFn = (
  campaign: TYPEFORM_CAMPAIGNS,
  updates:
    | Partial<FeedbackCampaignStatus>
    | ((
        currentStatus: FeedbackCampaignStatus,
      ) => Partial<FeedbackCampaignStatus>),
) => void;

const DEFAULT_FEEDBACK_CAMPAIGN_STATUS: FeedbackCampaignStatus = {
  shouldAskAgain: true,
  askAgainDate: new Date().toISOString(),
};

export const useFeedbackCampaignStatuses = (): [
  value: FeedbackCampaignStatuses,
  onChange: UpdateFeedbackCampaignStatusesFn,
] => {
  const [value, setValue] = useAppLocalStorage<FeedbackCampaignStatuses>(
    'feedbackCampaignStatuses',
  );

  const setValueProxy: UpdateFeedbackCampaignStatusesFn = useCallback(
    (campaign, updates) =>
      setValue((currentFeedbacks) => ({
        ...currentFeedbacks,
        [campaign]: {
          ...(currentFeedbacks?.[campaign] || DEFAULT_FEEDBACK_CAMPAIGN_STATUS),
          ...(isFunction(updates)
            ? updates(
                currentFeedbacks?.[campaign] ||
                  DEFAULT_FEEDBACK_CAMPAIGN_STATUS,
              )
            : updates),
        },
      })),
    [setValue],
  );

  return [value, setValueProxy];
};
