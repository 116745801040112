import { generatePath, Location } from 'react-router-dom';

import dayjs from 'dayjs';
import { StudentCourse, StudentViewFull } from '@eversity/types/domain';

import routes from '../../../react/routes';
import { TYPEFORMS } from './forms';

// Campaigns can be removed when they are in the past.
// We keep a unique reference to each mostly to differentiate which ones have already been
// answered by the student.
export enum TYPEFORM_CAMPAIGNS {
  SATISFACTION_SURVEY_Q1_2023 = 'SATISFACTION_SURVEY_Q1_2023',
  SATISFACTION_SURVEY_Q2_2023 = 'SATISFACTION_SURVEY_Q2_2023',
  SATISFACTION_SURVEY_Q3_2023 = 'SATISFACTION_SURVEY_Q3_2023',
  SATISFACTION_SURVEY_Q4_2023 = 'SATISFACTION_SURVEY_Q4_2023',
}

export type TypeformCampaignConfig = {
  typeform: TYPEFORMS;
  startDate: string;
  endDate: string;
  shouldAsk?: (params: {
    student: StudentViewFull;
    studentCourse: StudentCourse;
    location: Location;
  }) => boolean;
};

const satisfactionSurveyCampaign = {
  typeform: TYPEFORMS.SATISFACTION_SURVEY,
  shouldAsk: ({ student, studentCourse, location }) =>
    // Don't show on assignments page.
    !location.pathname.startsWith(
      generatePath(routes.ASSIGNMENTS.ROOT, {
        courseId: studentCourse.course.id,
        classId: studentCourse.class.id,
      }),
    ) &&
    // Don't show to students who started using the app less than 2 months ago.
    student.courses.some(
      (course) => dayjs().diff(course.firstConnectionDate, 'month', true) > 2,
    ),
} satisfies Partial<TypeformCampaignConfig>;

export const TYPEFORM_CAMPAIGN_CONFIGS: Record<
  TYPEFORM_CAMPAIGNS,
  TypeformCampaignConfig
> = {
  [TYPEFORM_CAMPAIGNS.SATISFACTION_SURVEY_Q1_2023]: {
    ...satisfactionSurveyCampaign,
    startDate: '2023-03-15',
    endDate: '2023-05-15',
  },
  [TYPEFORM_CAMPAIGNS.SATISFACTION_SURVEY_Q2_2023]: {
    ...satisfactionSurveyCampaign,
    startDate: '2023-06-15',
    endDate: '2023-07-15',
  },
  [TYPEFORM_CAMPAIGNS.SATISFACTION_SURVEY_Q3_2023]: {
    ...satisfactionSurveyCampaign,
    startDate: '2023-09-15',
    endDate: '2023-10-15',
  },
  [TYPEFORM_CAMPAIGNS.SATISFACTION_SURVEY_Q4_2023]: {
    ...satisfactionSurveyCampaign,
    startDate: '2023-12-15',
    endDate: '2024-01-15',
  },
};
