import { USER_COURSE_KPIS_TYPES } from '@eversity/domain/constants';
import { UserKpis, UserKpisHistory } from '@eversity/types/domain';

import { HttpRepository } from '../../httpRepository';

const BASE_USERS_URI = '/api/v1/users/users';

export class UserKpisRepository extends HttpRepository {
  /**
   * Get kpis of the user and its class.
   *
   * @param query - Query params.
   * @param query.courseId - Course id.
   * @param query.classId - Class id.
   * @returns - User and class kpis.
   */
  async getUserKpis(query: {
    courseId: string;
    classId: string;
  }): Promise<UserKpis> {
    const { body } = await this.http
      .get(`${BASE_USERS_URI}/me/kpis`)
      .query(query);

    return body;
  }

  /**
   * Get user's history kpis of a type and the class average value.
   *
   * @param query - Query params.
   * @param query.courseId - Course id.
   * @param query.classId - Class id.
   * @param query.type - Kpi type.
   * @returns List of user's kpis and class average value.
   */
  async getUserHistoryKpis(query: {
    courseId: string;
    classId: string;
    type: USER_COURSE_KPIS_TYPES;
  }): Promise<UserKpisHistory> {
    const { body } = await this.http
      .get(`${BASE_USERS_URI}/me/kpis-history`)
      .query(query);

    return body;
  }
}
