import { Location } from 'react-router-dom';

import dayjs from '@eversity/services/dayjs';
import { StudentCourse, StudentViewFull } from '@eversity/types/domain';

import { FeedbackCampaignStatuses } from '../../../types';
import { TYPEFORM_CAMPAIGNS, TYPEFORM_CAMPAIGN_CONFIGS } from './campaigns';
import { TYPEFORMS } from './forms';

export const getFirstEligibleCampaign = ({
  student,
  studentCourse,
  feedbackCampaignStatuses,
  location,
}: {
  student?: StudentViewFull;
  studentCourse?: StudentCourse;
  feedbackCampaignStatuses?: FeedbackCampaignStatuses;
  location: Location;
}): {
  campaign: TYPEFORM_CAMPAIGNS;
  typeform: TYPEFORMS;
  askAgainInMs: number;
} =>
  (student && studentCourse && location
    ? Object.entries(TYPEFORM_CAMPAIGN_CONFIGS)
        // Filter eligibleCampaigns.
        .filter(
          ([campaign, campaignConfig]) =>
            // Remove campaigns that the user ignored or already answered.
            (!feedbackCampaignStatuses?.[campaign] ||
              feedbackCampaignStatuses?.[campaign].shouldAskAgain) &&
            // Only keep active campaigns.
            dayjs().isSameOrAfter(campaignConfig.startDate, 'day') &&
            dayjs().isSameOrBefore(campaignConfig.endDate, 'day') &&
            // Filter based on the student, his active course, and the current location.
            // Should ask by default.
            (!campaignConfig.shouldAsk ||
              campaignConfig.shouldAsk({
                student,
                studentCourse,
                location,
              })),
        )
        // Map and sort by date to ask again ASC.
        .map(([campaign, campaignConfig]) => ({
          campaign: campaign as TYPEFORM_CAMPAIGNS,
          typeform: campaignConfig.typeform,
          askAgainInMs: dayjs(
            feedbackCampaignStatuses?.[campaign]?.askAgainDate || new Date(),
          ).diff(dayjs(), 'milliseconds'),
        }))
        .sort((a, b) => a.askAgainInMs - b.askAgainInMs)
    : // it shows the one at 11:05.
      // This way if we have 2 campaigns that should be shown after 11:05 and 11:06,
      // Get first element of sorted array.
      [])[0];
