import { USER_LESSON_PROGRESS_SEQUENCE_STATUSES } from '@eversity/domain/constants';
import {
  CreateUserLessonProgressBody,
  McqAnswer,
  UserLessonProgress,
} from '@eversity/types/domain';

import { HttpRepository } from '../../httpRepository';

const e = encodeURIComponent;

export class UserLessonsProgressRepository extends HttpRepository {
  /**
   * Get user lesson progresses.
   *
   * @returns The user lesson progresses.
   */
  async getUserLessonProgresses(): Promise<UserLessonProgress[]> {
    const { body } = await this.http.get(
      '/api/v1/users/users/me/lesson-progresses',
    );

    return body;
  }

  /**
   * Initialize a new user lesson progress for a lesson.
   *
   * @param params - Params.
   * @param params.courseId - Course id.
   * @param params.classId - Class id.
   * @param params.teachingUnitId - Teaching unit id.
   * @param params.lessonId - Lesson id.
   * @returns New user lesson progress.
   */
  async createUserLessonProgress(
    params: CreateUserLessonProgressBody,
  ): Promise<UserLessonProgress> {
    const { body } = await this.http
      .post('/api/v1/users/users/me/lesson-progresses')
      .send(params);

    return body;
  }

  /**
   * Set user progress current position.
   *
   * @param params - Params.
   * @param params.progressId - User lesson progress id.
   * @param params.sequenceVersionId - Last sequence id visited, or null to reset.
   * @returns The updated user progress.
   */
  async setUserLessonCurrentPosition({
    progressId,
    sequenceVersionId,
  }: {
    progressId: string;
    sequenceVersionId?: string | null;
  }): Promise<UserLessonProgress> {
    const { body } = await this.http
      .put(
        `/api/v1/users/users/me/lesson-progresses/${e(
          progressId,
        )}/positions/current`,
      )
      .send({
        sequenceVersionId,
      });

    return body;
  }

  /**
   * Set user sequence progress.
   *
   * @param params - Params.
   * @param params.progressId - User lesson progress id.
   * @param params.sequenceVersionId - Sequence id.
   * @param params.status - New status.
   * @returns The updated user progress.
   */
  async setUserLessonSequenceProgress({
    progressId,
    sequenceVersionId,
    status,
    exercise,
  }: {
    progressId: string;
    sequenceVersionId: string;
    status?: USER_LESSON_PROGRESS_SEQUENCE_STATUSES;
    exercise?: {
      isSubmitted?: boolean;
      exerciseVersionId?: string;
      exerciseAnswers?: McqAnswer[];
    };
  }): Promise<UserLessonProgress> {
    const { body } = await this.http
      .patch(
        `/api/v1/users/users/me/lesson-progresses/${e(
          progressId,
        )}/sequences/${e(sequenceVersionId)}`,
      )
      .send({
        status,
        exercise,
      });

    return body;
  }

  /**
   * Ping user activity on a sequence.
   *
   * @param params - Params.
   * @param params.progressId - User lesson progress id.
   * @param params.sequenceVersionId - Sequence id.
   * @returns The updated user progress.
   */
  async setUserLessonSequenceLastActiveTimestamp({
    progressId,
    sequenceVersionId,
  }: {
    progressId: string;
    sequenceVersionId: string;
  }): Promise<UserLessonProgress> {
    const { body } = await this.http.put(
      `/api/v1/users/users/me/lesson-progresses/${e(progressId)}/sequences/${e(
        sequenceVersionId,
      )}/pings/active`,
    );

    return body;
  }

  /**
   * Stop pinging user activity on a sequence.
   *
   * @param params - Params.
   * @param params.progressId - User lesson progress id.
   * @param params.sequenceVersionId - Sequence id.
   * @returns The updated user progress.
   */
  async unsetUserLessonSequenceLastActiveTimestamp({
    progressId,
    sequenceVersionId,
  }: {
    progressId: string;
    sequenceVersionId: string;
  }): Promise<UserLessonProgress> {
    const { body } = await this.http.delete(
      `/api/v1/users/users/me/lesson-progresses/${e(progressId)}/sequences/${e(
        sequenceVersionId,
      )}/pings/active`,
    );

    return body;
  }
}
