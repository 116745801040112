import { ASSIGNMENT_TYPES } from '@eversity/domain/constants';

export default {
  APP: {
    ROOT: '/',
  },

  HOME: {
    ROOT: '/:courseId/:classId',
  },

  COURSE: {
    ROOT: '/:courseId/:classId/course',
  },

  LESSON: {
    ROOT: '/:courseId/:classId/teaching-unit/:unitId/lesson/:lessonId',
    // SYNTHESIS: '/:courseId/:classId/teaching-unit/:unitId/lesson/:lessonId/synthesis',
  },

  ASSIGNMENTS: {
    ROOT: '/:courseId/:classId/assignments',
    [ASSIGNMENT_TYPES.HOMEWORK]:
      '/:courseId/:classId/assignments/:unitId/:assignmentId/:lessonId',
    [ASSIGNMENT_TYPES.EXAM]:
      '/:courseId/:classId/assignments/:unitId/:assignmentId',
  },

  APPOINTMENTS_SCHEDULING: {
    ROOT: '/:courseId/:classId/appointments-scheduling',
  },

  DOCUMENTS: {
    ROOT: '/:courseId/:classId/documents',
  },

  INTERNSHIPS: {
    ROOT: '/:courseId/:classId/internships',
  },

  EXAMS: {
    ROOT: '/:courseId/:classId/exams',
  },

  WORK_STUDY: {
    ROOT: '/:courseId/:classId/work-study',
  },

  VIRTUAL_CLASSROOMS: {
    // Virtual classrooms list.
    ROOT: '/:courseId/:classId/virtual-classrooms',
    // Virtual classroom info.
    ITEM: '/:courseId/:classId/virtual-classrooms/:virtualClassroomId',
    // Standalone route to join the meets.
    JOIN: '/virtual-classrooms/:virtualClassroomId',
  },

  CONTACT: {
    ROOT: '/:courseId/:classId/contact',
  },

  // Routes below are for the unauthenticated user
  GOOGLE_AUTH: {
    ROOT: '/auth/google',
  },

  LOGIN: {
    ROOT: '/login',
  },
} as const;
