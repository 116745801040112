import {
  Fragment,
  memo,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { useLocation } from 'react-router-dom';

import dayjs from '@eversity/services/dayjs';
import { useBoolState } from '@eversity/ui/utils';

import { TYPEFORM_CAMPAIGNS } from '../../../../../domain/feedbacks/typeform/campaigns';
import { TYPEFORMS } from '../../../../../domain/feedbacks/typeform/forms';
import {
  ASK_AGAIN_LATER_IN_DAYS,
  DELAY_AFTER_OPENING_APP_IN_MINUTES,
} from '../../../../../domain/feedbacks/typeform/constants';
import { getFirstEligibleCampaign } from '../../../../../domain/feedbacks/typeform';
import { useAuth } from '../../../../context/auth-context';
import { useUserLastVisitedCourse } from '../../../../hooks/local-storage/user';
import { useUserCourse } from '../../../../hooks/useUserCourse';
import { useFeedbackCampaignStatuses } from '../../../../hooks/local-storage/feedbacks';

import { FeedbackTypeformDialog } from '../../typeform/dialog/FeedbackTypeformDialog';

export type FeedbackCampaignsManagerProps = {
  children?: ReactNode;
};

const minOpenCampaignDate = dayjs().add(
  DELAY_AFTER_OPENING_APP_IN_MINUTES,
  'minutes',
);

export const FeedbackCampaignsManagerBase = ({
  children = null,
}: FeedbackCampaignsManagerProps) => {
  const [isOpen, onOpen, onClose] = useBoolState(false);
  const location = useLocation();
  const [feedbackCampaignStatuses, setFeedbackCampaignStatus] =
    useFeedbackCampaignStatuses();

  const { user } = useAuth();
  const [lastVisitedCourse] = useUserLastVisitedCourse();
  const studentCourse = useUserCourse(
    lastVisitedCourse?.courseId,
    lastVisitedCourse?.classId,
  );

  const firstEligibleCampaign: {
    campaign: TYPEFORM_CAMPAIGNS;
    typeform: TYPEFORMS;
    askAgainInMs: number;
  } = useMemo(
    () =>
      getFirstEligibleCampaign({
        student: user,
        studentCourse,
        feedbackCampaignStatuses,
        location,
      }),
    [user, studentCourse, location, feedbackCampaignStatuses],
  );

  useEffect(() => {
    if (firstEligibleCampaign?.typeform) {
      const timeoutHandle = setTimeout(
        () => onOpen(),
        // Open the dialog at least 5 minutes after the app is opened.
        // If the campaign must be opened after these 5 minutes,
        // open after the campaign is available.
        // If the 5 minutes have already passed and the campaign is available, open immediately.
        Math.max(
          0,
          dayjs(minOpenCampaignDate).diff(dayjs(), 'ms'),
          firstEligibleCampaign.askAgainInMs,
        ),
      );

      return () => clearTimeout(timeoutHandle);
    }

    return undefined;
  }, [firstEligibleCampaign, onOpen]);

  const onAskLater = useCallback(() => {
    onClose();

    setFeedbackCampaignStatus(
      firstEligibleCampaign.campaign,
      (campaignStatus) => ({
        // Don't override if shouldAskAgain is false.
        shouldAskAgain: campaignStatus.shouldAskAgain ?? true,
        // Ask again in 1 day, or later if the date was already later.
        askAgainDate: dayjs
          .max(
            ...[
              dayjs().add(ASK_AGAIN_LATER_IN_DAYS, 'day'),
              campaignStatus?.askAgainDate &&
                dayjs(campaignStatus?.askAgainDate),
            ].filter(Boolean),
          )
          .toISOString(),
      }),
    );
  }, [firstEligibleCampaign?.campaign, onClose, setFeedbackCampaignStatus]);

  const onNeverAskAgain = useCallback(() => {
    onClose();

    setFeedbackCampaignStatus(firstEligibleCampaign.campaign, {
      shouldAskAgain: false,
    });
  }, [firstEligibleCampaign?.campaign, setFeedbackCampaignStatus, onClose]);

  const onSubmit = useCallback(() => {
    setFeedbackCampaignStatus(firstEligibleCampaign.campaign, {
      shouldAskAgain: false,
    });
  }, [firstEligibleCampaign?.campaign, setFeedbackCampaignStatus]);

  return (
    <Fragment>
      {children}

      {!!(user && studentCourse && firstEligibleCampaign) && (
        <FeedbackTypeformDialog
          key={firstEligibleCampaign.typeform}
          typeform={firstEligibleCampaign.typeform}
          isOpen={isOpen}
          onRequestClose={onAskLater}
          onAskLater={onAskLater}
          onNeverAskAgain={onNeverAskAgain}
          courseId={studentCourse.course.id}
          classId={studentCourse.class.id}
          onSubmit={onSubmit}
          showIntro
        />
      )}
    </Fragment>
  );
};

FeedbackCampaignsManagerBase.displayName = 'FeedbackCampaignsManager';

export const FeedbackCampaignsManager = memo(FeedbackCampaignsManagerBase);
