import { Fragment, memo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { css } from '@emotion/react';
import { FormattedMessage } from 'react-intl';

import { legalDocumentPropTypes } from '@eversity/ui/domain';
import { legalDocumentTypesMessages } from '@eversity/ui/intl';
import { legalDocumentSanitizeSchema } from '@eversity/domain/html';
import {
  AlertBanner,
  Checkbox,
  Dialog,
  DialogProps,
  Typography,
} from '@eversity/ui/design-system';
import { LegalDocument } from '@eversity/types/domain';

import {
  CommonEditorContentContext,
  CommonEditorContentContextProvider,
} from '../../layout/common-editor-content-layout/contexts/CommonEditorContent.context';
import { ContentScrollSpyContextProvider } from '../../layout/common-editor-content-layout/contexts/ContentScrollSpy.context';
import CommonEditorContent from '../../layout/common-editor-content-layout/content/Content';

import messages, {
  messagesByLegalDocumentType,
} from './LegalDocumentsDialog.messages';
import * as styles from './LegalDocumentsDialog.styles';

export type LegalDocumentsDialogProps = Omit<
  DialogProps,
  'size' | 'preventClosing'
> & {
  legalDocument?: LegalDocument;
  isLoading?: boolean;
  hasUpdate?: boolean;
  requireConsent?: boolean;
  onConfirmConsent?: () => void;
};

export const LegalDocumentsDialogBase = ({
  legalDocument = null,
  isLoading = false,
  hasUpdate = false,
  requireConsent = false,
  onConfirmConsent = noop,
  ...props
}: LegalDocumentsDialogProps) => {
  const [hasAccepted, onChangeAccepted] = useState(false);
  const scrollContainerRef = useRef();

  const messagesOfType = messagesByLegalDocumentType[legalDocument?.type];

  return (
    <Dialog
      {...props}
      size={Dialog.SIZES.VERY_LARGE}
      preventClosing={requireConsent}
    >
      {
        /**
         * The dialog content needs the legalDocument, but the Dialog needs to be in the DOM for
         * the entrance animation.
         */
        !!legalDocument && (
          <Fragment>
            <CommonEditorContentContextProvider
              content={legalDocument.content}
              sanitizeSchema={legalDocumentSanitizeSchema}
            >
              <CommonEditorContentContext.Consumer>
                {({ content, contentOutline, sanitizeSchema }) => (
                  <ContentScrollSpyContextProvider
                    scrollContainerRef={scrollContainerRef}
                    contentOutline={contentOutline}
                  >
                    <Dialog.Body>
                      <div css={styles.headerContainer}>
                        <div css={styles.header}>
                          <Typography variant={Typography.VARIANTS.HEADING_1}>
                            <FormattedMessage
                              {...legalDocumentTypesMessages[
                                legalDocument.type
                              ]}
                            />
                          </Typography>

                          <Typography
                            variant={Typography.VARIANTS.BODY_MEDIUM_REGULAR}
                          >
                            <FormattedMessage
                              {...messages.LAST_UPDATE}
                              values={{
                                createdAt: new Date(legalDocument.createdAt),
                              }}
                            />
                          </Typography>
                        </div>

                        {!!hasUpdate && (
                          <AlertBanner
                            title={
                              <FormattedMessage
                                {...messagesOfType.UPDATE_TITLE}
                              />
                            }
                            variant={AlertBanner.VARIANTS.WARNING}
                          >
                            <Typography
                              variant={Typography.VARIANTS.BODY_MEDIUM_REGULAR}
                              element="p"
                            >
                              <FormattedMessage
                                {...messagesOfType.UPDATE_BODY}
                              />
                            </Typography>
                          </AlertBanner>
                        )}
                      </div>
                    </Dialog.Body>

                    <Dialog.Body ref={scrollContainerRef}>
                      <CommonEditorContent
                        content={content as string}
                        sanitizeSchema={sanitizeSchema}
                      />
                    </Dialog.Body>
                  </ContentScrollSpyContextProvider>
                )}
              </CommonEditorContentContext.Consumer>
            </CommonEditorContentContextProvider>

            {!!requireConsent && (
              <Dialog.Actions>
                <div
                  css={css`
                    display: flex;
                    flex-direction: column;
                    gap: 20px;
                    align-items: flex-end;
                  `}
                >
                  <Checkbox
                    checked={hasAccepted}
                    onChange={onChangeAccepted}
                    description={
                      <FormattedMessage
                        {...messagesOfType.CHECKBOX_DESCRIPTION}
                      />
                    }
                  />

                  <Dialog.Action
                    onClick={onConfirmConsent}
                    disabled={!hasAccepted || isLoading}
                    variant={Dialog.Action.VARIANTS.PRIMARY}
                  >
                    <FormattedMessage {...messagesOfType.ACCEPT} />
                  </Dialog.Action>
                </div>
              </Dialog.Actions>
            )}
          </Fragment>
        )
      }
    </Dialog>
  );
};

LegalDocumentsDialogBase.displayName = 'LegalDocumentsDialog';

LegalDocumentsDialogBase.propTypes = {
  /** Legal document. Keep optional so the Dialog can be in the DOM for the open animation. */
  legalDocument: legalDocumentPropTypes,
  /** Is the consent being confirmed. */
  isLoading: PropTypes.bool,
  /** The legal document has been updated since the last time it was accepted. */
  hasUpdate: PropTypes.bool,
  /** If true, the user cannot close the dialog and a footer with a checkbox is shown. */
  requireConsent: PropTypes.bool,
  /** If requireConsent is true, callback when the user has confirmed consent. */
  onConfirmConsent: PropTypes.func,
};

export default memo(LegalDocumentsDialogBase);
