import { BrowserRouter as Router } from 'react-router-dom';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { HelmetProvider } from 'react-helmet-async';

import { DesignSystemProvider } from '@eversity/ui/design-system';
import { IntlProvider, SentryErrorBoundary } from '@eversity/ui/utils';

import { queryClient } from '../client';

import { AuthProvider } from './auth-context';
import { LocaleProvider } from '../generic/intl/locale-provider/LocaleProvider';
import ErrorView from '../views/error/ErrorView';
import AppRouter from '../AppRouter';

const AppProviders = () => (
  <HelmetProvider>
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools buttonPosition="bottom-right" />
      <LocaleProvider>
        <IntlProvider>
          <DesignSystemProvider>
            <Router>
              <SentryErrorBoundary fallback={<ErrorView />}>
                <AuthProvider>
                  <AppRouter />
                </AuthProvider>
              </SentryErrorBoundary>
            </Router>
          </DesignSystemProvider>
        </IntlProvider>
      </LocaleProvider>
    </QueryClientProvider>
  </HelmetProvider>
);

AppProviders.propTypes = {};

export default AppProviders;
