import { ComponentProps, memo } from 'react';
import { noop } from 'lodash';
import { css } from '@emotion/react';
import { Widget } from '@typeform/embed-react';

import { Dialog, DialogProps } from '@eversity/ui/design-system';
import { useBoolState } from '@eversity/ui/utils';

import { useUser } from '../../../../hooks/queries/user';
import { useUserCourse } from '../../../../hooks/useUserCourse';
import {
  TYPEFORMS,
  TYPEFORM_CONFIGS,
} from '../../../../../domain/feedbacks/typeform/forms';
import config from '../../../../../config/env';
import { FeedbackTypeformDialogIntro } from './intro/FeedbackTypeformDialogIntro';

export type FeedbackTypeformDialogProps = Pick<
  DialogProps,
  'isOpen' | 'onRequestClose'
> &
  Pick<ComponentProps<typeof Widget>, 'onSubmit'> & {
    typeform: TYPEFORMS;
    courseId: string;
    classId: string;
    onAskLater?: () => void;
    onNeverAskAgain?: () => void;
    showIntro?: boolean;
  };

const widgetStyle = {
  width: '100%',
  height: '100%',
};

const iframeProps = {
  // Display block otherwise it overflows in the dialog.
  style: 'display: block;',
};

export const FeedbackTypeformDialogBase = ({
  isOpen = false,
  onRequestClose = noop,
  typeform,
  courseId,
  classId,
  onSubmit = noop,
  onAskLater = noop,
  onNeverAskAgain = noop,
  showIntro = false,
}: FeedbackTypeformDialogProps) => {
  const { data: user } = useUser();
  const userCourse = useUserCourse(courseId, classId);

  const [shouldShowTypeform, onShowTypeform] = useBoolState(!showIntro);

  const { id: typeformId, useHiddenFields } = TYPEFORM_CONFIGS[typeform];

  const hiddenFields = useHiddenFields(user, userCourse);

  return (
    <Dialog
      size={Dialog.SIZES.VERY_LARGE}
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      shouldCloseOnClickOverlay
      shouldCloseOnEscape
      outlineCloseButton
      closeButtonPosition={Dialog.CLOSE_BUTTON_POSITIONS.ABSOLUTE}
    >
      {shouldShowTypeform ? (
        <div
          css={css`
            height: 600px;
            overflow: hidden;
          `}
        >
          <div
            css={css`
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
            `}
          >
            <Widget
              id={typeformId}
              style={widgetStyle}
              iframeProps={iframeProps}
              hidden={hiddenFields}
              onSubmit={onSubmit}
              enableSandbox={config.app.env === 'test'}
            />
          </div>
        </div>
      ) : (
        <FeedbackTypeformDialogIntro
          onShowTypeform={onShowTypeform}
          onAskLater={onAskLater}
          onNeverAskAgain={onNeverAskAgain}
        />
      )}
    </Dialog>
  );
};

export const FeedbackTypeformDialog = memo(FeedbackTypeformDialogBase);
