import { memo } from 'react';
import { noop } from 'lodash';
import { css } from '@emotion/react';

import {
  Button,
  Dialog,
  HtmlFormatter,
  LinkButton,
  Typography,
} from '@eversity/ui/design-system';
import { FormattedMessage } from 'react-intl';

import hamster from './hamster.png';
import messages from './FeedbackTypeformDialogIntro.messages';

export type FeedbackTypeformDialogIntroProps = {
  onShowTypeform?: () => void;
  onAskLater?: () => void;
  onNeverAskAgain?: () => void;
};

export const FeedbackTypeformDialogIntroBase = ({
  onShowTypeform = noop,
  onAskLater = noop,
  onNeverAskAgain = noop,
}: FeedbackTypeformDialogIntroProps) => (
  <div
    css={css`
      height: 600px;
      display: flex;
      align-items: stretch;
    `}
  >
    <Dialog.Body
      css={css`
        flex: 1 1 0;
        min-width: 450px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      `}
    >
      {/* Empty div for the space-between. */}
      <div />

      <div
        css={css`
          display: flex;
          flex-direction: column;
          gap: 40px;
        `}
      >
        <HtmlFormatter>
          <FormattedMessage
            {...messages.PROMPT}
            values={{
              h4: (chunks) => (
                <Typography variant={Typography.VARIANTS.HEADING_4}>
                  {chunks}
                </Typography>
              ),
              p: (chunks) => (
                <Typography
                  variant={Typography.VARIANTS.BODY_MEDIUM_REGULAR}
                  element="p"
                >
                  {chunks}
                </Typography>
              ),
              b: (chunks) => (
                <Typography variant={Typography.VARIANTS.BODY_MEDIUM_BOLD}>
                  {chunks}
                </Typography>
              ),
            }}
          />
        </HtmlFormatter>

        <div
          css={css`
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 16px;
          `}
        >
          <Button
            onClick={onShowTypeform}
            size={Button.SIZES.LARGE}
            variant={Button.VARIANTS.PRIMARY}
          >
            <FormattedMessage {...messages.ANSWER} />
          </Button>

          <Button
            onClick={onAskLater}
            size={Button.SIZES.SMALL}
            variant={Button.VARIANTS.NEUTRAL}
            outline
          >
            <FormattedMessage {...messages.ASK_LATER} />
          </Button>
        </div>
      </div>

      <div>
        <LinkButton onClick={onNeverAskAgain}>
          <FormattedMessage {...messages.NEVER_ASK_AGAIN} />
        </LinkButton>
      </div>
    </Dialog.Body>

    <div
      css={css`
        background-image: url(${hamster});
        background-size: cover;
        background-position: center;
        flex: 1 1 0;
      `}
    />
  </div>
);

FeedbackTypeformDialogIntroBase.displayName = 'FeedbackTypeformDialogIntro';

FeedbackTypeformDialogIntroBase.propTypes = {};

export const FeedbackTypeformDialogIntro = memo(
  FeedbackTypeformDialogIntroBase,
);
