import { InternshipEnums } from '@eversity/types/domain';

import { HttpRepository } from '../httpRepository';

export class InternshipsRepository extends HttpRepository {
  /**
   * Get enums for internships data.
   *
   * @returns {object} Map of enums.
   */
  async getEnums(): Promise<InternshipEnums> {
    const { body } = await this.http.get('/api/v1/school/internships-enums');

    return body;
  }
}
