import { defineMessages, MessageDescriptor } from 'react-intl';

import { LEGAL_DOCUMENT_TYPES } from '@eversity/domain/constants';

export default defineMessages({
  LAST_UPDATE: {
    defaultMessage: 'Dernière modification : {createdAt, date, short}',
  },
} as const);

export const messagesByLegalDocumentType: Record<
  LEGAL_DOCUMENT_TYPES,
  {
    UPDATE_TITLE: MessageDescriptor;
    UPDATE_BODY: MessageDescriptor;
    CHECKBOX_DESCRIPTION: MessageDescriptor;
    ACCEPT: MessageDescriptor;
  }
> = {
  [LEGAL_DOCUMENT_TYPES.PRIVACY_POLICY]: defineMessages({
    UPDATE_TITLE: {
      defaultMessage: 'Mise à jour de la politique de confidentialité',
    },

    UPDATE_BODY: {
      defaultMessage:
        "Nous vous invitons à prendre connaissance des dernières modifications apportées à la politique de confidentialité avant d'accepter la nouvelle version de celle-ci.",
    },

    CHECKBOX_DESCRIPTION: {
      defaultMessage: "J'ai lu et j'accepte la politique de confidentialité.",
    },

    ACCEPT: {
      defaultMessage: 'Accepter la politique de confidentialité',
    },
  } as const),

  [LEGAL_DOCUMENT_TYPES.SCHOOL_RULES]: defineMessages({
    UPDATE_TITLE: {
      defaultMessage: 'Mise à jour du règlement intérieur',
    },

    UPDATE_BODY: {
      defaultMessage:
        "Nous vous invitons à prendre connaissance des dernières modifications apportées au règlement intérieur avant d'accepter la nouvelle version de celui-ci.",
    },

    CHECKBOX_DESCRIPTION: {
      defaultMessage: "J'ai lu et j'accepte le règlement intérieur.",
    },

    ACCEPT: {
      defaultMessage: 'Accepter le règlement intérieur',
    },
  } as const),
} as const;
