import { useCallback } from 'react';
import { isFunction } from 'lodash';

import { useAppLocalStorage } from './useAppLocalStorage';
import { LastVisitedCourse, UserSettings } from '../../../types';

export const useUserLastVisitedCourse = () =>
  useAppLocalStorage<LastVisitedCourse>('lastVisitedCourse');

type UpdateSettingsFn = (
  updates:
    | Partial<UserSettings>
    | ((currentSettings: UserSettings) => Partial<UserSettings>),
) => void;

export const useUserSettings = (): [
  value: UserSettings,
  onChange: UpdateSettingsFn,
] => {
  const [value, setValue] = useAppLocalStorage<UserSettings>('settings');

  const setValueProxy: UpdateSettingsFn = useCallback(
    (settings) =>
      setValue((currentSettings) => ({
        ...currentSettings,
        ...(isFunction(settings) ? settings(currentSettings) : settings),
      })),
    [setValue],
  );

  return [value, setValueProxy];
};

export const useOnboardingMeetingLastRequestDate = (
  courseId: string,
  classId: string,
) =>
  useAppLocalStorage<string>(
    `onboardingMeetingLastRequestDate-${courseId}-${classId}`,
    {
      serialize: (v) => v,
      deserialize: (v) => v,
    },
  );
